import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NoData from '../components/NoData';

function Withdrawal() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [status, setStatus] = useState('success');
  const [amount, setAmount] = useState(0);
  const [additionalAmount, setAdditionalAmount] = useState('');
  const [cid,setCid]=useState(0)

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://backend.royale98.in/api/v3/withdraws/list');
      setUsers(response.data);
    } catch (err) {
      console.error('Failed to fetch withdraws', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleAction = (user) => {
    setSelectedUser(user);
    setModalVisible(true);
    setAmount(user.amount)
    if(user.amount>=300){
      setAdditionalAmount(158)
    }else{
      setAdditionalAmount(0);
    }
  };

  const handleSubmit = async () => {
    // Placeholder for actual submission logic
    const payload = {
      user_id: selectedUser.user_id,
      status: status,
      id:selectedUser.id,
      amount: amount, 
    };

    try {
      try {
        
  
        const response = await axios.post('https://backend.royale98.in/api/v3/withdraws/update', payload);
        if (response.data.success) {
          alert(response.data.message);
          
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.error('Error updating withdraw:', error);
        alert('Failed to update withdraw');
      }
  
      // await axios.post('/api/update-status', payload);

      // Close modal after submission
      setModalVisible(false);
      fetchUsers(); // Refresh the list after submission
    } catch (error) {
      console.error('Failed to update status:', error);
    }
  };

  return (
    <div>
      <h4>Withdraws</h4>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {users.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Id</th>
                  <th>Mobile</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>Datetime</th> 
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={user.id}>
                    <td>{index + 1}</td>
                    <td>{user.id}</td>
                    <td>{user.mobile}</td>
                    <td>{user.amount}</td>
                    <td>{user.status}</td> 
                    <td>{user.created_at}</td>
                    <td>
                      <button onClick={() => handleAction(user)}>Action</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <NoData label="No Request Found, please try again later" />
          )}
        </>
      )}

      {modalVisible && (
        <div className="modal">
          <h4>Update Withdraw Status</h4>
          <label>
            Status:
            <select value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value="success">Success</option>
              <option value="failed">Failed</option>
            </select>
          </label>
          <label>
            Amount:
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </label>
           
          <button onClick={handleSubmit}>Submit</button>
          <button onClick={() => setModalVisible(false)}>Cancel</button>
        </div>
      )}
    </div>
  );
}

export default Withdrawal;
