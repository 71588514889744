import CryptoJS from 'crypto-js';

const secretKey = 'chandankushwaha';

export const handleEncrypt = (text) => {
  return CryptoJS.AES.encrypt(text, secretKey).toString();
};

export const handleDecrypt = (encryptedText) => {
  const bytes = CryptoJS.AES.decrypt(encryptedText, secretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};
