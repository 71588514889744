import { Bars3Icon, UserIcon } from '@heroicons/react/16/solid'
import React from 'react'
import { useDispatch } from 'react-redux'

function Navbar({sideBarOpen}) {
  const dispatch = useDispatch();
  return (
    <div className='navbar'>
        <ul>
            <li><Bars3Icon onClick={()=>dispatch({type:'SIDEBAR_TOGGLE',payload: !sideBarOpen})}/></li>
            <li>
                Admin Panel
            </li>
            <li>
                <UserIcon/>
            </li>
        </ul>
    </div>
  )
}

export default Navbar