import React, { useState } from 'react';
import { handleEncrypt } from '../redux/crytp'; // Import handleEncrypt from crypto.js
import { useDispatch } from 'react-redux';

function Login() {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const dispatch= useDispatch();

  const handleLogin = () => {
    // Check credentials
    if (username === 'admin' && password === 'admin') {
      // Encrypt user ID and password
      const userId = 'admin'; // You can use a different user ID if needed
      const combinedData = `${userId}+${password}`;
      const encryptedData = handleEncrypt(combinedData);

      // Store encrypted data in localStorage
      localStorage.setItem('userData', encryptedData);

      // Set success message
      setMessage('Login successful! Encrypted data stored in localStorage.');
      dispatch({type:'LOGIN'});
    } else {
      // Set error message
      setMessage('Invalid username or password.');
    }
  };

  return (
    <div className='login-container'>
      <div className='login-box'>
        <h2>Admin Panel Login</h2>
        <label>Username</label>
        <input
          type='text'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <label>Password</label>
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button className='btn btn-login' onClick={handleLogin}>
          Login
        </button>
        <div>
          <p style={{color:'red',textAlign:'center'}}>{message}</p>
        </div>
      </div>
      {message && (
        <div>
          <h3>{message}</h3>
        </div>
      )}
    </div>
  );
}

export default Login;
