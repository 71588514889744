import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NoData from '../components/NoData';

function Recharges() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [status, setStatus] = useState('success');
  const [amount, setAmount] = useState(0);
  const [additionalAmount, setAdditionalAmount] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [filterDate, setFilterDate] = useState('');

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://backend.royale98.in/api/v3/recharges/list');
      setUsers(response.data);
    } catch (err) {
      console.error('Failed to fetch users', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleAction = (user) => {
    setSelectedUser(user);
    setModalVisible(true);
    setAmount(user.amount);
    if (user.amount >= 300) {
      setAdditionalAmount(158);
    } else {
      setAdditionalAmount(0);
    }
  };

  const handleSubmit = async () => {
    const payload = {
      user_id: selectedUser.id,
      status: status,
      utr_code: selectedUser.recharge_code,
      amount: amount,
      additional_amount: status === 'success' ? additionalAmount : 0,
    };

    try {
      const response = await axios.post('https://backend.royale98.in/api/v3/recharges/update', payload);
      if (response.data.success) {
        alert('Recharge updated successfully');
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Error updating recharge:', error);
      alert('Failed to update recharge');
    }

    setModalVisible(false);
    fetchUsers();
  };

  const filterUsers = () => {
    return users.filter(user => {
      const matchesStatus = filterStatus ? user.status === filterStatus : true;
      const matchesDate = filterDate ? new Date(user.created_at).toLocaleDateString() === new Date(filterDate).toLocaleDateString() : true;
      return matchesStatus && matchesDate;
    });
  };

  const getTotalAmount = () => {
    return filterUsers().reduce((sum, user) => sum + user.amount, 0);
  };

  return (
    <div>
      <h4>Recharges</h4>
      <div>
        <label>
          Status:
          <select value={filterStatus} onChange={(e) => setFilterStatus(e.target.value)}>
            <option value="">All</option>
            <option value="success">Success</option>
            <option value="failed">Failed</option>
          </select>
        </label>
        <label>
          Date:
          <input
            type="date"
            value={filterDate}
            onChange={(e) => setFilterDate(e.target.value)}
          />
        </label>
      </div>
      <div>
        <h4>Total Amount: {getTotalAmount()}</h4>
      </div>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {filterUsers().length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Id</th>
                  <th>Mobile</th>
                  <th>Amount</th>
                  <th>Status</th>
                  <th>UTR</th>
                  <th>Date Time</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {filterUsers().map((user, index) => (
                  <tr key={user.id}>
                    <td>{index + 1}</td>
                    <td>{user.id}</td>
                    <td>{user.mobile}</td>
                    <td>{user.amount}</td>
                    <td>{user.status}</td>
                    <td>{user.utr_id}</td>
                    <td>{user.created_at}</td>
                    <td>
                      <button onClick={() => handleAction(user)}>Action</button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <NoData label="No Request Found, please try again later" />
          )}
        </>
      )}

      {modalVisible && (
        <div className="modal">
          <h4>Update Recharge Status</h4>
          <label>
            Status:
            <select value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value="success">Success</option>
              <option value="failed">Failed</option>
            </select>
          </label>
          <label>
            Amount:
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </label>
          {status === 'success' && (
            <label>
              Additional Amount:
              <input
                type="number"
                value={additionalAmount}
                onChange={(e) => setAdditionalAmount(e.target.value)}
              />
            </label>
          )}
          <button onClick={handleSubmit}>Submit</button>
          <button onClick={() => setModalVisible(false)}>Cancel</button>
        </div>
      )}
    </div>
  );
}

export default Recharges;
