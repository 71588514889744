import React from 'react'

function NoData({label='No Data Found'}) {
  return (
    <div style={{width:'inherit',textAlign:'center',backgroundColor:'#bebebe42',padding:'40px 20px'}}>
        <img src='https://cdn-icons-png.flaticon.com/256/6477/6477031.png'/>
        <h4 style={{fontWeight:400}}>{label}</h4>
    </div>

  )
}

export default NoData