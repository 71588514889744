import React, { useEffect, useState } from 'react'
import NoData from '../components/NoData';
import { PieChart } from '@mui/x-charts/PieChart';
import axios from 'axios';


function Games() {
  const games=['Parity','Sapre','Bcone','Emerd'];
  const [loading,setLoading]=useState(true);
  const [currentGame,setCurrentGame]=useState(games[0]);
  const [currentNumber,setCurrentNumber]=useState(0);
  const [gameData,setGameData]=useState({});
  const getGames = async () => {
    try {
      const response = await axios.get('https://backend.royale98.in/api/v3/games/get'); // Adjust the endpoint as needed
      setGameData(response.data);
      setLoading(false)
      setCurrentNumber(gameData.games[currentGame.toLowerCase()])
    } catch (err) {
      // setError('Failed to fetch gifts');
    }
  };
  const postCurrentNumber = async (num) => {
    try {
      await axios.post('https://backend.royale98.in/api/v3/games/set', { // Adjust the endpoint as needed
        game_id: gameData.games.game_id,
        gametype:currentGame.toLowerCase(),
        current_number: num,
      });
      // setCurrentNumber(num)
     window.location.reload()
    } catch (err) {
      console.error('Failed to post current number', err);
    }
  };
  useEffect(()=>{
    getGames()
    
  },[currentGame])
  if(loading==false){
  return (
    
    <div className='games-container'>
      <h4>Games</h4>
     <ul>
      {games.map((e)=><li key={e} onClick={()=>setCurrentGame(e)} className={currentGame==e?'active':''}>{e}</li>)}
     </ul>
     <br/> 
     <ul>
      <li>Upcoming  GameId :{gameData.games.game_id} </li>
      <li>
          Upcoming Result : {gameData.games[currentGame.toLowerCase()]}
      </li>
     </ul>
     <br/>
     <h4>Choose Options to manage {currentGame}</h4>
     <ul style={{marginTop:'20px'}}>
      {[0,1,2,3,4,5,6,7,8,9].map((e)=><li onClick={()=>postCurrentNumber(e)} className={gameData.games[currentGame.toLowerCase()]==e?'active':''}>{e}</li>)}
     </ul>
      
     <PieChart
  series={[
    {
      data: gameData.selections[currentGame.toLowerCase()],
    },
  ]}
  width={400}
  height={200}
/>

    </div>
  )}
  else{
    return (<h1>Loading</h1>);
  }
}

export default Games