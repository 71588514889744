import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import NoData from '../components/NoData';

// Set the root element for modal (important for accessibility)
Modal.setAppElement('#root');

function Gift() {
  const [gifts, setGifts] = useState([]);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [formData, setFormData] = useState({ id: '', code: '', amount: '', numbers: '' });
  const [error, setError] = useState('');

  useEffect(() => {
    fetchGifts();
  }, []);

  const copyLink = (name) => {
    const link ="https://royale98.in/env?code="+name; // Get the current URL
    navigator.clipboard.writeText(link).then(() => {
     
      alert("Copied Successful")// Reset after 2 seconds
    }).catch(err => {
      console.error('Failed to copy: ', err);
    });
  };

  const fetchGifts = async () => {
    try {
      const response = await axios.get('https://backend.royale98.in/api/v3/envelope/list'); // Adjust the endpoint as needed
      setGifts(response.data);
    } catch (err) {
      // setError('Failed to fetch gifts');
    }
  };

  const openModal = (gift = {}) => {
    setFormData(gift);
    setIsEditMode(Boolean(gift.id));
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setFormData({ id: '', code: '', amount: '', numbers: '' });
    setError('');
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if (isEditMode) {
        await axios.post('https://backend.royale98.in/api/v3/envelope/update', formData); // Adjust the endpoint as needed
      } else {
        await axios.post('https://backend.royale98.in/api/v3/envelope/add', formData); // Adjust the endpoint as needed
      }
      fetchGifts();
      closeModal();
    } catch (err) {
      setError('Failed to save gift');
    }
  };

  const handleDelete = async (id) => {
    try {
      await axios.post('https://backend.royale98.in/api/v3/envelope/delete', { id }); // Adjust the endpoint as needed
      fetchGifts();
    } catch (err) {
      setError('Failed to delete gift');
    }
  };

  return (
    <div>
      <ul style={{display:'flex' ,flexDirection:'row' ,justifyContent:'space-between'}}>
        <li>
            <h4>Gift</h4>
        </li>
        <li>
        <button onClick={() => openModal()}>Add Gift</button>
        </li>
      </ul> 
      
      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Id</th>
            <th>Envelope Code</th>
            <th>Amount</th>
            <th>Total Recievers</th>
            <th>Total Claim</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {gifts.length === 0 ? (
            <></>
          ) : (
            gifts.map((gift, index) => (
              <tr key={gift.id}>
                <td>{index + 1}</td>
                <td>{gift.id}</td>
                <td>{gift.code}</td>
                <td>{gift.amount}</td>
                <td>{gift.numbers}</td>
                <td>0</td>
                <td> 
                  <button onClick={()=>copyLink(gift.code)}>Copy</button>
                  <button onClick={() => handleDelete(gift.id)}>Delete</button>
                </td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      {gifts.length === 0 ? (
            <NoData label='No Gift Found please create one' />
          ) :(<></>)}

      {/* Modal for adding/editing gifts */}
      <Modal
      style={{ content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    width:'90vw',
    maxWidth:'400px',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)', 

  },}}
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Gift Modal"
      >
        <h2>{isEditMode ? 'Edit Gift' : 'Add Gift'}</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label>Code:</label>
            <input
              type="text"
              name="code"
              value={formData.code}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Amount for each reciever:</label>
            <input
              type="number"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Number of Recievers:</label>
            <input
              type="number"
              name="numbers"
              value={formData.numbers}
              onChange={handleChange}
              required
            />
          </div>
          {error && <p>{error}</p>}
          <button type="submit">{isEditMode ? 'Update' : 'Add'} Gift</button>
          <button type="button" className='cancel-btn' onClick={closeModal}>Cancel</button>
        </form>
      </Modal>
    </div>
  );
}
 
export default Gift;
