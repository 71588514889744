import React, { useEffect, useState } from 'react';
import NoData from '../components/NoData';
import axios from 'axios'; 
import Modal from 'react-modal';

function Users() {
  const [users, setUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]); // For filtered users
  const [searchTerm, setSearchTerm] = useState(''); // Search term state
  const [currentPage, setCurrentPage] = useState(1); // Pagination state
  const usersPerPage = 20; // Users per page
  const [changed, setChanged] = useState(false);
  const [wallet,setWallet]=useState(0);
  const [selectedUser, setSelectedUser] = useState(null); // For modal
  const [modalVisible, setModalVisible] = useState(false); // Modal visibility

  const updateWallet=async()=>{
    await axios.post('https://backend.royale98.in/api/v3/users/wallet', { // Adjust the endpoint as needed
      wallet: wallet,
      id:selectedUser.id
       
    });
    
    fetchUsers()
    closeModal()
  }
   
  useEffect(() => {
    fetchUsers();
  }, [changed]);

  useEffect(() => {
    // Filter users based on the search term
    const filtered = users.filter((user) => 
      user.ref_code.toLowerCase().includes(searchTerm.toLowerCase()) ||
      user.mobile.includes(searchTerm)
    );
    setFilteredUsers(filtered);
  }, [searchTerm, users]);
 
  const handleUpdate = async (id, status) => {
    try {
      await axios.post('https://backend.royale98.in/api/v3/users/update', {
        id: id,
        status: status,
      });
      setChanged(!changed);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://backend.royale98.in/api/v3/users/list');
      setUsers(response.data);
    } catch (err) {
      console.error('Failed to fetch users', err);
    }
  };

  const openModal = (user) => {
    setSelectedUser(user);
    setModalVisible(true);
  };

  const closeModal = () => {
    setModalVisible(false);
    setSelectedUser(null);
  };

  // Pagination logic
  const indexOfLastUser = currentPage * usersPerPage;
  const indexOfFirstUser = indexOfLastUser - usersPerPage;
  const currentUsers = filteredUsers.slice(indexOfFirstUser, indexOfLastUser);
  const totalPages = Math.ceil(filteredUsers.length / usersPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div>
      <h4>Users</h4>

      {/* Search Bar */}
      <input
        type="text"
        placeholder="Search by Userid or Mobile"
        value={searchTerm}
        className='search-input'
        onChange={(e) => setSearchTerm(e.target.value)}
      />

      <table>
        <thead>
          <tr>
            <th>#</th>
            <th>Mobile</th>
            <th>Userid</th>
            <th>Withdraw Password / Password</th>
            <th>Wallet</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {currentUsers.map((user) => (
            <tr key={user.id}>
              <td>{user.id}</td>
              <td>{user.mobile}</td>
              <td>{user.ref_code}</td>
              <td>{user.withdraw_password}</td>
              <td>{user.wallet}</td> 
              <td>
                <button
                  className={user.status === 1 ? 'block-btn' : 'unblock-btn'}
                  onClick={() => handleUpdate(user.id, user.status === 1 ? 0 : 1)}
                  type="button"
                >
                  {user.status === 1 ? 'Block' : 'Unblock'}
                </button>
                <button
                  type="button"
                  onClick={() => openModal(user)}
                >
                  View More
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {currentUsers.length < 1 && <NoData label="No Users Found, please try again later" />}

      {/* Pagination */}
      {filteredUsers.length > usersPerPage && (
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => paginate(index + 1)}
              className={currentPage === index + 1 ? 'active' : ''}
            >
              {index + 1}
            </button>
          ))}
        </div>
      )}

      <Modal
        style={{ content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          width: '90vw',
          maxWidth: '400px',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)', 
        }}}
        isOpen={modalVisible}
        onRequestClose={closeModal}
        contentLabel="User Modal"
      >
        <div className="modal-box">
          {selectedUser !== null && (
            <div className="modal-content">
              <span className="close" onClick={closeModal}>&times;</span>
              <h2>User Info</h2>
              <p><strong>ID:</strong> {selectedUser.id}</p>
              <p><strong>Name:</strong> {selectedUser.name}</p>
              <p><strong>Mobile:</strong> {selectedUser.mobile}</p>
              <p><strong>Wallet:</strong> {selectedUser.wallet}</p>
              <p><strong>Email Verified At:</strong> {selectedUser.email_verified_at}</p>
              <p><strong>Role:</strong> {selectedUser.role}</p>
              <p><strong>Avatar:</strong> <img src={selectedUser.avatar} alt="User Avatar" style={{ width: '50px', borderRadius: '50%' }} /></p>
              <p><strong>Referral Code:</strong> {selectedUser.ref_code}</p>
              <p><strong>Referred By:</strong> {selectedUser.ref_by || 'N/A'}</p>
              <p><strong>Status:</strong> {selectedUser.status === 1 ? 'Blocked' : 'Active'}</p>
              <p><strong>Created At:</strong> {new Date(selectedUser.created_at).toLocaleString()}</p>
              <p><strong>Updated At:</strong> {new Date(selectedUser.updated_at).toLocaleString()}</p>
              <input type="number" value={wallet} onChange={(e)=>setWallet(e.target.value)}/>
              <button type="button" onClick={()=>updateWallet()}>Save</button>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default Users;
