const initialState={
    sideBarOpen:false,
    isAuthenticated:false
}

export const reducer = (state = initialState,action)=>{
 switch(action.type){
    case 'SIDEBAR_TOGGLE':
        return {...state,sideBarOpen : action.payload};
    case 'LOGIN':
        return {...state, isAuthenticated:true};
    case 'LOGOUT':
        return {...state, isAuthenticated:false };
    default:
        return state;
 }
}