import React, { useEffect } from 'react'
import { Route, Router, Routes , BrowserRouter } from 'react-router-dom'
import Home from './pages/Home'
import Login from './pages/Login'
import Withdrawal from './pages/Withdrawal'
import Recharges from './pages/Recharges'
import Gift from './pages/Gift'
import Games from './pages/Games'
import NavSidebar from './components/NavSidebar'
import Users from './pages/Users'
import Navbar from './components/Navbar'
import { useDispatch, useSelector } from 'react-redux'
import { handleDecrypt } from './redux/crytp'
import BankCard from './pages/BankCard'


function App() {
  const dispatch = useDispatch();

  useEffect(() => {
    const encryptedData = localStorage.getItem('userData');
    if (encryptedData) {
      const decryptedData = handleDecrypt(encryptedData);
      const [userId, password] = decryptedData.split('+');
      
      // Check if credentials match
      if (userId === 'admin' && password === 'admin') {
        dispatch({ type: 'LOGIN' });
      } else {
        dispatch({ type: 'LOGOUT' });
      }
    } else {
      dispatch({ type: 'LOGOUT' });
    }
  }, [dispatch]);
  const sideBarOpen = useSelector((state)=>state.auth.sideBarOpen);
  const isAuthenticated = useSelector((state)=>state.auth.isAuthenticated);
  return (
 <>
 <div className='main'>
 <BrowserRouter>
  <NavSidebar sideBarOpen={sideBarOpen} />
  <div className='content'>
    <Navbar sideBarOpen={sideBarOpen}/>
    <div className='contentarea'> 
  <Routes>
  <Route path="/"  element={!isAuthenticated?<Login/>: <Home/>}/>
    <Route path="/home"  element={!isAuthenticated?<Login/>: <Home/>}/>
    <Route path="/login" element={isAuthenticated?<Home/>: <Login/>}/>
    <Route path="/users" element={!isAuthenticated?<Login/>: <Users />}/>
    <Route path="/recharges" element={!isAuthenticated?<Login/>: <Recharges/>}/>
    <Route path="/withdrawals" element={!isAuthenticated?<Login/>: <Withdrawal/>}/>
    <Route path="/gifts" element={!isAuthenticated?<Login/>: <Gift/>}/>
    <Route path="/games" element={!isAuthenticated?<Login/>: <Games/>}/>
    <Route path="/bank-cards" element={!isAuthenticated?<Login/>: <BankCard/>}/>
    
  </Routes>
  </div>
  </div>
 </BrowserRouter>
 
 </div>
 </>
  )
}

export default App