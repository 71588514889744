import React from 'react';

const cardData = [
  { title: 'Total Users', value: 1200 },
  { title: 'Total Recharges', value: 300 },
  { title: 'Total Active Users', value: 850 },
  { title: 'Total Games', value: 150 },
  { title: 'Total Withdrawals', value: 75 },
  { title: 'Total Registrations Today', value: 20 }
];

function Home() {
  return (
    
    <div className='home-container'>
    
      {cardData.map((card, index) => (
        <div key={index} className='card'>
          <h3>{card.title}</h3>
          <p>{card.value}</p>
        </div>
      ))}
    </div>
  );
}

export default Home;
