import React, { useState, useEffect } from 'react';
import axios from 'axios';
import NoData from '../components/NoData';

function BankCard() {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [status, setStatus] = useState('success');
  const [amount, setAmount] = useState(0);
  const [additionalAmount, setAdditionalAmount] = useState('');
  const [cid, setCid] = useState(0);

  const fetchUsers = async () => {
    try {
      const response = await axios.get('https://backend.royale98.in/api/v3/bankdata/list');
      setUsers(response.data);
    } catch (err) {
      console.error('Failed to fetch users', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleAction = (user) => {
    setSelectedUser(user);
    setModalVisible(true);
    setAmount(user.amount);
    if (user.amount >= 300) {
      setAdditionalAmount(158);
    } else {
      setAdditionalAmount(0);
    }
  };

  const handleSubmit = async () => {
    const payload = {
      user_id: selectedUser.user_id,
      status: status,
      id: selectedUser.id,
      amount: amount,
    };

    try {
      const response = await axios.post('https://backend.royale98.in/api/v3/withdraws/update', payload);
      if (response.data.success) {
        alert(response.data.message);
      } else {
        alert(response.data.message);
      }
    } catch (error) {
      console.error('Error updating withdraw:', error);
      alert('Failed to update withdraw');
    }

    setModalVisible(false);
    fetchUsers();
  };

  const totalAmount = users.reduce((sum, user) => sum + user.amount, 0);

  return (
    <div>
      <h4>Bank Card</h4>
      <p>Total Amount: {totalAmount}</p>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {users.length > 0 ? (
            <table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Id</th>
                  <th>User Id</th>
                  <th>Bank Name</th>
                  <th>Account Number</th>
                  <th>IFSC</th>
                  <th>Person Name</th>
                  <th>Mobile Number</th>
                  <th>Email</th>
                  <th>Status</th>
                  <th>Created At</th>
                  <th>Updated At</th>
                  <th>UPI</th>
                  
                </tr>
              </thead>
              <tbody>
                {users.map((user, index) => (
                  <tr key={user.id}>
                    <td>{index + 1}</td>
                    <td>{user.id}</td>
                    <td>{user.user_id}</td>
                    <td>{user.bank_name}</td>
                    <td>{user.account_number}</td>
                    <td>{user.ifsc}</td>
                    <td>{user.person_name}</td>
                    <td>{user.mobile_number}</td>
                    <td>{user.email}</td>
                    <td>{user.status}</td>
                    <td>{user.created_at}</td>
                    <td>{user.updated_at}</td>
                    <td>{user.upi}</td>
                     
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <NoData label="No Request Found, please try again later" />
          )}
        </>
      )}

      {modalVisible && (
        <div className="modal">
          <h4>Update Withdraw Status</h4>
          <label>
            Status:
            <select value={status} onChange={(e) => setStatus(e.target.value)}>
              <option value="success">Success</option>
              <option value="failed">Failed</option>
            </select>
          </label>
          <label>
            Amount:
            <input
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              required
            />
          </label>
          <button onClick={handleSubmit}>Submit</button>
          <button onClick={() => setModalVisible(false)}>Cancel</button>
        </div>
      )}
    </div>
  );
}

export default BankCard;
