import { CurrencyDollarIcon, GifIcon, PowerIcon, UserIcon, WalletIcon, XMarkIcon } from '@heroicons/react/16/solid';
import React from 'react'
import { useDispatch } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
 

function NavSidebar({sideBarOpen}) {
  const location = useLocation();
  const dispatch=useDispatch();
  const logout= ()=>{
    localStorage.clear();
    dispatch({type:'LOGOUT'});
  }
  const sidebarOptions =[[ <WalletIcon/>,'Home' ],[ <UserIcon />  ,'Games'],[<UserIcon/>  ,'Users'],[<CurrencyDollarIcon/>  ,'Recharges'],[<WalletIcon/>  ,'Withdrawals'],[<GifIcon/>  ,'Gifts'],[<GifIcon/>  ,'Bank-cards'],[ <PowerIcon/>  ,'Logout']];
  return (
    <div className={`sidebar ${sideBarOpen?`sidebar-visible`:''}`}>
        <p style={{float:'right'}}><XMarkIcon onClick={()=>dispatch({type:'SIDEBAR_TOGGLE',payload: !sideBarOpen})}/></p>
        <ul>
             {sidebarOptions.map((sidebar,index)=>
                <li key ={index} className={location.pathname=="/"+sidebar[1].toLowerCase()?'active':''}  >
                  {sidebar[1]!='Logout'?
                    <Link to={"/"+sidebar[1].toLowerCase()}>
                       <span> {sidebar[0]} </span> <span>{sidebar[1]}</span>  
                    </Link>
                    : <><a href="#"><span onClick={()=>logout()}>  {sidebar[0]} </span> <span onClick={()=>logout()}>{sidebar[1]}</span></a> </>  
                  }
                </li>
             )}
        </ul>
    </div>
  )
}

export default NavSidebar